import { Component } from "@angular/core";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { TravelTypeService } from "booking-app-v2/shared/services/initializers/travel-type.service";
import {
  GlobalDataEnum,
  LandingPagePointsCalculationConfig,
  TravelType,
} from "booking-app-v2/shared/types";
import { LandingPage } from "booking-app-v2/shared/models/landing-page.model";
import { CookiesService } from "booking-app-v2/shared/services/initializers/cookies.service";
import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";

@Component({
  templateUrl: `/html/whitelabel/${window.KaligoConfig.whitelabel}/search_v2`,
})
export class LandingPageComponent {
  landingPage: LandingPage = this.globalDataService.get(
    GlobalDataEnum.LANDING_PAGE,
  );
  landingPagePointsCalculationConfig: LandingPagePointsCalculationConfig =
    this.appSettingsService.landingPagePointsCalculationConfig;
  readonly isUob443BookingCancellationEnabled: boolean;
  tenant: string = this.appSettingsService.tenant.toLowerCase();

  constructor(
    private travelTypeService: TravelTypeService,
    private globalDataService: GlobalData,
    private appSettingsService: AppSettingsService,
    private cookiesService: CookiesService,
    private bootstrapDataService: BootstrapDataService,
  ) {
    this.isUob443BookingCancellationEnabled =
      this.bootstrapDataService.bootstrapData.feature_flags.uob_443_cancel_booking;
  }

  backgroundImageStyle(): { [key: string]: string } {
    return {
      "background-image": `url(${this.landingPage.background_image_url})`,
    };
  }

  get totalAmount(): number {
    return (
      this.landingPagePointsCalculationConfig.nights *
      this.landingPagePointsCalculationConfig.perNight *
      this.landingPagePointsCalculationConfig.milesMultiplier
    );
  }

  get travelType(): TravelType {
    return this.globalDataService.get(GlobalDataEnum.TRAVEL_TYPE);
  }
}
