import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom, Observable, Subject, Subscription } from "rxjs";

import { CancellationPolicy, Currency, LandingPage, Locale, PointsPartner, User } from "booking-app-v2/shared/models";
import {
  BookingTransactionData,
  CancellationPolicyResponse,
  Card,
  CHECKOUT_RESULT_NAME,
  CheckoutFormData,
  CheckoutFormUserDetails,
  CheckoutResults,
  Country,
  GlobalDataEnum,
  PartnerScore,
  PaymentMethod,
  PhoneCode,
  PRODUCT_TYPE,
  SavedCard,
  State,
} from "booking-app-v2/shared/types";
import { Hotel, HotelSearchForm, Room } from "booking-app-v2/hotels/models";
import { TimeUtils } from "booking-app-v2/shared/utils";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { CheckoutService } from "booking-app-v2/shared/services/checkout/checkout.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { CouponService } from "booking-app-v2/shared/services/coupon.service";
import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";
import { CountryService } from "booking-app-v2/shared/services/country.service";
import { CreditCardValidationService } from "booking-app-v2/shared/services/credit-card-validation.service";
import { PaymentFormUtilService } from "booking-app-v2/shared/services/payment-form-util.service";
import { VoucherService } from "booking-app-v2/shared/services/voucher.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { BookingErrorsMapperService } from "booking-app-v2/shared/services/booking-errors-mapper.service";
import { PaymentMethodService } from "booking-app-v2/shared/services/payment-method.service";
import { kaligoConstants } from "booking-app-v2/shared/constants/kaligo-constants";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";
import { WhitelabelTranslateService } from "booking-app-v2/shared/services/whitelabel-translate.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  templateUrl: "/html/hotels/checkout_v2",
})
export class CheckoutPageComponent implements OnInit, OnDestroy {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  readonly landingPage: LandingPage;
  readonly pointsPartner: PointsPartner;
  readonly checkoutData: CheckoutResults;
  readonly user: User;
  readonly displayBookingForSomeone: boolean;
  readonly showForSomeoneElse: boolean;
  readonly isUserLoggedIn: boolean;
  readonly hideUserAddressFromProfile: boolean;
  readonly countryOptions: Country[];
  readonly displayUserDetail: boolean;
  readonly displayPoweredByKaligo: boolean;
  readonly showCancellationPolicy: boolean;
  readonly cancellationFee: number;
  readonly cancellationFeeText: string;

  userPaymentMethodIsFetching: boolean = true;
  familyMilesSelected: boolean;
  displayFamilyMiles: boolean;
  hotel: Hotel;
  room: Room;
  bookingTransactionData: BookingTransactionData;
  checkoutForm: FormGroup;
  checkoutFormData: CheckoutFormData;
  checkoutFormError: string;
  filteredPhoneCountriesList$: Observable<PhoneCode[]>;
  selectedCurrency: Currency;
  selectedLocale: Locale;
  autoPopulateAddress: boolean;
  hotelSearchForm: HotelSearchForm;
  displayCheckInDate: string;
  displayCheckOutDate: string;
  labelTaxRecoveryCharges: string;
  salesTaxIncluded: string;
  propertyFeeIncluded: string;
  hotelOccupancyTaxIncluded: string;
  cancellationPolicy: CancellationPolicy;
  loadingCancellationPolicy: boolean;
  nightsArr: number[];
  cardType: Card;
  warnAmex: boolean;
  partnerScore: PartnerScore;
  poweredByAscenda: boolean;
  showBookingTooltip: boolean;
  creditCardExpirationYears: string[];
  checkoutFormSubmitted: boolean;
  showLoyaltyProgramTnC: boolean;
  withConfidence: string;

  checkoutFormSubmittedEmitter: Subject<void> = new Subject<void>();

  constructor(
    private globalData: GlobalData,
    public appSettingsService: AppSettingsService,
    private checkoutService: CheckoutService,
    private currenciesService: CurrenciesService,
    private pointsCashShareService: PointsCashShareService,
    private payWithPointsCashService: PayWithPointsCashService,
    private translateService: TranslateService,
    private countryService: CountryService,
    public couponService: CouponService,
    public voucherService: VoucherService,
    public creditCardValidationService: CreditCardValidationService,
    private bootstrapDataService: BootstrapDataService,
    private pageDiscoveryService: PageDiscoveryService,
    public paymentFormUtilService: PaymentFormUtilService,
    private windowRefService: WindowRefService,
    private cdRef: ChangeDetectorRef,
    private bookingErrorsMapperService: BookingErrorsMapperService,
    private paymentMethodService: PaymentMethodService,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
    private whitelabelTranslateService: WhitelabelTranslateService,
  ) {
    // things we need from globalData
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
    this.user = this.globalData.get(GlobalDataEnum.CURRENT_USER);
    this.selectedCurrency = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    this.selectedLocale = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE);
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.hideUserAddressFromProfile = this.appSettingsService.hideUserAddressFromProfile;
    this.isUserLoggedIn = this.globalData.isUserLoggedIn();

    // things we need in views
    this.checkoutData = this.checkoutService.results;
    this.displayBookingForSomeone = this.shouldDisplayBookingForSomeone();
    this.displayUserDetail = this.shouldDisplayUserDetail();
    this.displayPoweredByKaligo = this.kaligoConfig.applicationType === "whitelabel";
    this.labelTaxRecoveryCharges = this.getLabelTaxRecoveryCharges();
    this.showCancellationPolicy = this.shouldShowCancellationPolicy();
    this.loadingCancellationPolicy = true;
    this.cancellationFee = this.bootstrapDataService.bootstrapData.cancellation_fee_percentage;
    this.cancellationFeeText = this.appSettingsService.cancellationFeeText.checkout;
    this.poweredByAscenda = this.appSettingsService.poweredByAscenda;
    this.creditCardExpirationYears = kaligoConstants.creditCardExpirationYears.map((year) => year.toString());
    this.showLoyaltyProgramTnC = this.appSettingsService.showLoyaltyProgramTnC;
    this.withConfidence = this.appSettingsService.abTest.withConfidence;

    // init subscriptions
    this.initCurrencySubscription();
    this.initPointsCashShareSubscription();
    this.initCreditCardValidationSubscription();
    this.initPointsPartnerChangeSubscription();
    this.initPartnerScoreUpdateSubscription();
    this.initCheckoutErrorKeySubscription();
    this.initRebuildRoomOnValidateVoucherSubscription();
  }

  phoneCodeDisplayFn = (phoneCode: PhoneCode) => phoneCode?.text ?? "";
  countryDisplayFn = (country: Country) => country?.name ?? "";
  stateDisplayFn = (state: State) => state?.name ?? "";

  ngOnInit() {
    // using setTimeout here to avoid ExpressionChangedAfterItHasBeenCheckedError
    // caused by the async execution of this.checkoutService.pageLoad()
    setTimeout(() => this.initCheckoutPageComponent());
  }

  ngOnDestroy() {
    this.voucherService.resetVoucherService();
    this.checkoutService.resetCheckoutResults();
  }

  submitCheckoutForm(): void {
    this.paymentMethodService.selectedSavedCard = this.checkoutForm.controls.selectedSavedCard.value;
    this.checkoutFormSubmitted = true;
    this.checkoutFormSubmittedEmitter.next();
    this.checkoutService.processBooking({
      checkoutForm: this.checkoutForm,
      room: this.room,
      hotel: this.hotel,
      familyMilesSelected: this.displayFamilyMiles ? this.familyMilesSelected : false,
      voucherIds: this.voucherService.vouchers.map(voucher => voucher.uid),
      cardType: this.cardType,
      membership: this.checkoutFormData.membershipData,
    });
  }

  redirectToHotelDetailsPage(): void {
    this.hotelsUrlBuilderService.redirectToHotelDetailsPage(
      this.windowRefService.getURIParamFromWindow(),
    );
  }

  showSavedCreditCards(): boolean {
    return this.paymentMethodService.allowSaveCreditCard() && this.paymentMethodService.savedCards?.length > 0;
  }

  addNewCreditCard(): boolean {
    return !this.checkoutForm.controls.selectedSavedCard.value ||
      !this.showSavedCreditCards() ||
      this.appSettingsService.hasMultiplePaymentMethods;
  }

  onFamilyMilesSelectChanged(familyMilesSelected: boolean): void {
    this.familyMilesSelected = familyMilesSelected;
  }

  policyShowCash(): boolean {
    return this.landingPage.earnMiles();
  }

  policyShowPercentage(): boolean {
    return this.landingPage.hasProductType(PRODUCT_TYPE.REDEEM);
  }

  clauseNonRefundableTranslationText(): string {
    return this.translateService.instant("booking_detail.cancellation_policy.clause.non_refundable", {
      start_date: TimeUtils.format(this.cancellationPolicy.non_refundable_from, "lll"),
    });
  }

  switchAddressPopulate(): void {
    this.autoPopulateAddress = !this.autoPopulateAddress;
    if (this.autoPopulateAddress) {
      this.populateAddressFields();
    } else {
      this.clearAddressFields();
    }
  }

  customCheckoutCreditingTerms(): boolean {
    return this.pointsPartner.name === "CommBank" || this.pointsPartner.name === "DBS Bank Singapore";
  }

  notAllowedToBook(): boolean {
    return this.invalidVoucher() || this.insufficientPoints();
  }

  shouldShowBookingTooltip(): boolean {
    return this.appSettingsService.insufficientPointsCheck && this.showBookingTooltip && this.notAllowedToBook();
  }

  resetCouponCode(): void {
    this.couponService.resetCouponCode();
  }

  setPaymentActiveTab(paymentMethod: PaymentMethod): void {
    this.paymentMethodService.setActiveTab(paymentMethod);
  }

  getLoyaltyProgramCheckboxText(): string {
    return this.whitelabelTranslateService.translate("payment_details_and_preferred_loyalty_program_terms");
  }

  async initRoom(): Promise<void> {
    this.room = await firstValueFrom<Room>(
      this.checkoutData[CHECKOUT_RESULT_NAME.FETCH_PRICE],
    );
    this.displayFamilyMiles = this.shouldDisplayFamilyMiles();
    this.updatePartnerScoreFromPrice();
    this.updateRoomPriceToCreditCardValidationService();
    this.initTaxesAndFees();
  }

  get isLoading(): boolean {
    return this.globalData.get(GlobalDataEnum.IS_LOADING);
  }

  private addBookingKeyInBlackList(currentBookingKey: string): void {
    const currentBookingKeysBlackList = this.globalData.get(GlobalDataEnum.BOOKING_KEYS_BLACK_LIST);
    currentBookingKeysBlackList.push(currentBookingKey);
    this.globalData.set(GlobalDataEnum.BOOKING_KEYS_BLACK_LIST, currentBookingKeysBlackList);
  }

  private getLabelTaxRecoveryCharges(): string {
    const currentUserCountry = this.countryService.getCountry(this.hotel?.original_metadata.country);
    if (currentUserCountry && currentUserCountry.continent === "EU") {
      return this.translateService.instant("checkout.label.tax_recovery_charges_only");
    } else {
      return this.translateService.instant("checkout.label.tax_recovery_charges");
    }
  }

  private getSalesTaxIncluded(): string {
    return this.translateService.instant("checkout.label.include_tax", {
      tax: this.translateService.instant("checkout.label.sales_tax"),
      currency: this.selectedCurrency.code,
      amount: this.room?.priceInfo?.salesTax,
    });
  }

  private getPropertyFeeIncluded(): string {
    return this.translateService.instant("checkout.label.include_tax", {
      tax: this.translateService.instant("checkout.label.property_fee"),
      currency: this.selectedCurrency.code,
      amount: this.room?.priceInfo?.propertyFee,
    });
  }

  private getHotelOccupancyTaxIncluded(): string {
    return this.translateService.instant("checkout.label.include_tax", {
      tax: this.translateService.instant("checkout.label.hotel_occupancy"),
      currency: this.selectedCurrency.code,
      amount: this.room?.priceInfo?.hotelOccupancyTax,
    });
  }

  private shouldDisplayFamilyMiles(): boolean {
    if (!this.user || !this.room) {
      return false;
    }

    return this.user.family_miles > this.room.priceInfo.points_payment &&
      this.user.family_miles > this.user.redemption_points_balance;
  }

  private shouldDisplayBookingForSomeone(): boolean {
    return !this.landingPage.hasProductType(PRODUCT_TYPE.VOUCHER) &&
      this.pointsPartner.category === "airline";
  }

  private shouldDisplayUserDetail(): boolean {
    if (this.appSettingsService.alwaysAskForEmail) {
      return true;
    }

    const currentUser: User = this.globalData.get(GlobalDataEnum.CURRENT_USER);
    if (this.globalData.isUserLoggedIn() && this.appSettingsService.useMembershipNoAsEmail) {
      return !currentUser.redemption_member_no;
    } else {
      return !this.globalData.isUserLoggedIn() ||
        !currentUser.email ||
        !currentUser.first_name;
    }
  }

  private async initCheckoutPageComponent(): Promise<void> {
    await this.checkoutService.pageLoad();

    this.initHotel();
    this.initRoom();
    this.initCheckoutFormAndSavedCards();
    this.initCheckoutFormData();
    this.initBookingTransactionData();
    this.initHotelSearchForm();
    this.initCheckinCheckoutDates();
    this.initCancellationPolicy();
    this.initNightsArr();
  }

  private async initHotel(): Promise<void> {
    this.hotel = await firstValueFrom<Hotel>(this.checkoutData[CHECKOUT_RESULT_NAME.FETCH_INFO]);
  }

  private async initBookingTransactionData(): Promise<void> {
    this.bookingTransactionData = await this.checkoutService.initBookingTransactionData();
  }

  private async initCheckoutFormData(): Promise<void> {
    this.checkoutFormData = await this.checkoutService.initCheckoutFormData();
  }

  private async initCheckoutFormAndSavedCards(): Promise<void> {
    const [
      { checkoutForm, filteredPhoneCountriesList$ },
      storedPayments,
    ] = await this.checkoutService.initCheckoutFormAndSavedCards();
    this.checkoutService.initSavedCards(storedPayments);

    this.checkoutForm = checkoutForm;
    this.filteredPhoneCountriesList$ = filteredPhoneCountriesList$;
    this.autoPopulateAddress = this.checkoutService.isAddressFilled(this.checkoutForm);
    // init saved cards
    this.userPaymentMethodIsFetching = false;
    this.checkoutService.initSelectedSavedCard(this.checkoutForm);
  }

  private initCurrencySubscription(): void {
    this.currenciesService.onCurrencyChange
      .pipe(untilDestroyed(this))
      .subscribe((newCurrency: Currency) => {
        this.selectedCurrency = newCurrency;
        this.rebuildRoom();
        this.paymentMethodService.filterSavedCards();
        this.checkoutService.initSelectedSavedCard(this.checkoutForm);
      });
  }

  private initPointsCashShareSubscription(): void {
    this.pointsCashShareService.getSliderUpdate()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.rebuildRoom();
        this.cdRef.detectChanges();
      });
  }

  private async initCancellationPolicy(): Promise<void> {
    const { policy, invalid, bookingKey } = await firstValueFrom<CancellationPolicyResponse>(
      this.checkoutData[CHECKOUT_RESULT_NAME.FETCH_POLICY],
    );
    if (invalid) {
      this.addBookingKeyInBlackList(bookingKey);
    } else {
      this.cancellationPolicy = policy;
      this.loadingCancellationPolicy = false;
    }
  }

  private rebuildRoom(): void {
    this.room.priceInfo.hotelPriceIsReady = false;
    this.room = new Room(
      this.appSettingsService,
      this.globalData,
      this.currenciesService,
      this.payWithPointsCashService,
      this.pageDiscoveryService,
      this.voucherService.vouchers,
      this.room.roomRawPrice,
    );
    this.updateRoomPriceToCreditCardValidationService();
    this.initTaxesAndFees();
  }

  private initHotelSearchForm(): void {
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
  }

  private initCheckinCheckoutDates(): void {
    this.displayCheckInDate = TimeUtils.localiseAndFormat(this.hotelSearchForm.checkInDate,
      this.appSettingsService.bookingSummaryDateFormat);
    this.displayCheckOutDate = TimeUtils.localiseAndFormat(this.hotelSearchForm.checkOutDate,
      this.appSettingsService.bookingSummaryDateFormat);
  }

  private initNightsArr(): void {
    this.nightsArr = Array.from(Array(this.hotelSearchForm.duration).keys());
  }

  private updatePartnerScoreFromPrice(): void {
    this.creditCardValidationService.onPartnerScoreUpdated.next({
      base: this.room?.priceInfo?.points ?? this.partnerScore.base,
      bonus: this.room?.priceInfo?.bonus ?? this.partnerScore.bonus,
    });
  }

  private updateRoomPriceToCreditCardValidationService(): void {
    this.creditCardValidationService.roomPrice = this.room?.priceInfo;
  }

  private initCreditCardValidationSubscription(): void {
    this.creditCardValidationService.onCreditCardValidated
      .pipe(untilDestroyed(this))
      .subscribe(({ cardType, warnAmex }) => {
        this.cardType = cardType;
        this.warnAmex = warnAmex;
        this.cdRef.detectChanges();
      });
  }

  private initPointsPartnerChangeSubscription(): void {
    this.creditCardValidationService.onPointsPartnerChanged
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        /*
        angular-v2-todo:
        migrate v1's hotel checkout controller displayChangePartnerCallback to here when migrating Kaligo
        */
        // updatePartnerScoreFromPrice()
        // getSingleRoomPrice()
        // $scope.ccValidation()
      });
  }

  private initPartnerScoreUpdateSubscription(): void {
    this.creditCardValidationService.onPartnerScoreUpdated
      .pipe(untilDestroyed(this))
      .subscribe((newPartnerScore: PartnerScore) => {
        this.partnerScore = newPartnerScore;
      });
  }

  private initCheckoutErrorKeySubscription(): void {
    this.checkoutData.checkoutErrorKey$
      .pipe(untilDestroyed(this))
      .subscribe((checkoutErrorKey: string) => {
        this.checkoutFormError = this.bookingErrorsMapperService.map(checkoutErrorKey);
      });
  }

  private shouldShowCancellationPolicy(): boolean {
    if (this.landingPage.hasProductType(PRODUCT_TYPE.REDEEM)) {
      return this.appSettingsService.showCancelPolicyOnRedeem;
    } else if (this.landingPage.hasProductType(PRODUCT_TYPE.EARN)) {
      return this.appSettingsService.showCancelPolicyOnEarn;
    } else {
      return false;
    }
  }

  private invalidVoucher(): boolean {
    return (
      this.landingPage?.hasProductType(PRODUCT_TYPE.VOUCHER) &&
      this.voucherService.vouchers.length < this.hotelSearchForm.duration
    );
  }

  private insufficientPoints(): boolean {
    return (
      this.appSettingsService.insufficientPointsCheck &&
      this.landingPage?.hasProductType(PRODUCT_TYPE.REDEEM) &&
      this.room?.priceInfo &&
      this.user.redemption_points_balance < this.room.priceInfo.points_payment
    );
  }

  private populateAddressFields(): void {
    const userDetails: CheckoutFormUserDetails = this.checkoutFormData.checkoutFormUserDetails;
    this.checkoutForm.controls.address1.setValue(userDetails.address1 ?? "");
    this.checkoutForm.controls.city.setValue(userDetails.city ?? "");
    this.checkoutForm.controls.zipCode.setValue(userDetails.zipCode ?? "");
    this.checkoutForm.controls.country.setValue(userDetails.country ?? "");
    this.checkoutForm.controls.state.setValue(userDetails.state ?? "");
  }

  private clearAddressFields(): void {
    this.checkoutForm.controls.address1.setValue("");
    this.checkoutForm.controls.city.setValue("");
    this.checkoutForm.controls.zipCode.setValue("");
    this.checkoutForm.controls.country.setValue("");
    this.checkoutForm.controls.state.setValue("");
  }

  private initRebuildRoomOnValidateVoucherSubscription(): void {
    this.voucherService.triggerRebuildPriceInfo
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.rebuildRoom();
      });
  }

  private initTaxesAndFees(): void {
    this.salesTaxIncluded = this.getSalesTaxIncluded();
    this.propertyFeeIncluded = this.getPropertyFeeIncluded();
    this.hotelOccupancyTaxIncluded = this.getHotelOccupancyTaxIncluded();
  }
}
